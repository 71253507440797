import Vue from 'vue'
import { LiffListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      useRow: true,
      searchFilterOrder: 0,
      targetKey: 'store_id',
      pageTitle: 'module.client',
      search: 'client.search_keyword',
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      liffFilter: {
        status: {
          label: '',
          type: 'selection',
          //@ts-ignore
          options: this.vm.$providerStoreCooperationList,
          optionTextPrefix: 'provider.status',
        },
      },
      selectedData: row => row,
      batch: {
        batch_requet_cooperation: {
          label: '批次同意合作',
          column: 'status',
          value: 'cooperation',
          targetLabel: row => row.name,
          disabled: (row) => {
            return row.status !== 'request'
          }
        },
        batch_cooperation_reject: {
          label: '批次取消合作',
          column: 'status',
          value: 'reject',
          targetLabel: row => row.name,
          disabled: (row) => {
            return row.status !== 'cooperation'
          }
        },
        batch_requet_reject: {
          label: '批次拒絕合作',
          column: 'status',
          value: 'reject',
          targetLabel: row => row.name,
          disabled: (row) => {
            return row.status !== 'request'
          }
        }
      },
      listItem: () => import('@/modules/provider/components/clientItem/liffListClientItem.vue'),
    }

  }
}

export default new listConfig()
